import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import { CardContent, Typography } from "@mui/material";

const CustomersSays = ({ publishReview }) => {
  let lang = localStorage.getItem("selected_language");

  const { t } = useTranslation();

  return (
    <>
      {publishReview.length > 0 ? (
        <section className="customers_says">
          <div className="container">
            <div className="center_tittle">
              <h2>{t("What our customers say")}</h2>
              <h5>{t("Thousands of people get benefit")}</h5>
            </div>
            <div className="customers_says_row">
              <div className="owl-carousel says_carousel">
                {publishReview?.map((data, index) => {
                  let feedback =
                    lang === "en" && data.feedback && data.feedback.length > 5
                      ? data.feedback
                      : data.arabic_feedback || data.feedback;
                  let description =
                    lang === "en" &&
                    data.description &&
                    data.description.length > 5
                      ? data.description
                      : data.arabic_description || data.description;
                  return (
                    <Card
                      className="item"
                      key={index}
                      sx={{
                        // minHeight: 300,
                        marginTop: 5,
                        marginBottom: 5,
                        textAlign: "center",
                        fontFamily: "inherit",
                      }}>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          sx={{
                            mt: 3,
                            fontFamily: "inherit",
                            fontWeight: "bold",
                          }}>
                          {data.user_id.first_name} {data.user_id.last_name}
                        </Typography>
                        <div
                          style={{
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            minHeight: "200px",
                          }}>
                          <Typography gutterBottom variant="h5" component="div">
                            {feedback}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            {description}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default CustomersSays;

"use client";
import { useTranslation } from "react-i18next";
import TransformationsSlider from "./TransformationsSlider";
import { useEffect, useState } from "react";
import axios from "@/api/axios";
import { GET_PUBLISHED_TRANSFORMATIONS } from "@/api/apiUrl";

const Transformations = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(GET_PUBLISHED_TRANSFORMATIONS);
        setData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      {data && data.length > 0 && (
        <section className="transformations pb-0 pb-md-5">
          <div className="container">
            <div className="center_tittle pb-0 mb-0">
              <h2> {t("Top Transformation Clients")}</h2>
              <h5>{t("subscribe now")}</h5>
            </div>
            <div className="mt-0 mt-md-5" >
              <TransformationsSlider data={data} />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Transformations;

import { useTranslation } from "react-i18next";

const ChooseUs = () => {
  const { t } = useTranslation();

  return (
    <section className="chooseus_area" id="story">
      <div className="container-fulid">
        <div className="row chooseus_row m-0">
          <div className="col-md-5 choose_img">
            <h2>{t("Raad Story")}</h2>
          </div>
          <div className="col-md-7 choose_content">
            <div className="media">
              <div className="media-body">
                <h3>{t("A lifetime journey")}</h3>
                <p className="text-justify">{t("Home-discription2")}</p>
                <p className="text-justify">{t("Home-discription3")}</p>
                <p className="text-justify">{t("Home-discription4")}</p>
                <a
                  data-toggle="collapse"
                  data-parent="#questions_aq"
                  href="#accordion1"
                  className="collapsed"
                >
                  {t("Read More")}
                </a>
                <div id="accordion1" className="collapse" role="tabpanel">
                  <p className="text-justify">{t("Home-discription5")}</p>
                  <p className="text-justify">{t("Home-discription6")}</p>
                  <p className="text-justify">{t("Home-discription7")}</p>
                  <p className="text-justify">{t("Home-discription8")}</p>
                  <p className="text-justify">{t("Home-discription9")}</p>
                  <p className="text-justify">{t("Home-discription10")}</p>
                  <p className="text-justify">{t("Home-discription11")}</p>
                  <p className="text-justify">{t("Home-discription12")}</p>
                  <p className="text-justify">{t("Home-discription13")}</p>
                  <p className="text-justify">{t("Home-discription14")}</p>
                  <p className="text-justify">{t("Home-discription15")}</p>
                  <p className="text-justify">{t("Home-discription16")}</p>
                  <p className="text-justify">{t("Home-discription17")}</p>
                  <p className="text-justify">{t("Home-discription18")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;

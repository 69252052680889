"use client";

import React, { useState, useEffect } from "react"; // Import React and hooks
import { GET_YOUTUBE_LINK, GET_PUBLISH_RECORD } from "@/api/apiUrl";
import NewHomeSlider from "./NewHomeSlider";
import AboutUs from "./AboutUs";
import Cta from "./Cta";
import ChooseUs from "./ChooseUs";
import CustomersSays from "./CustomersSays";
import Transformations from "./Transformations";

const fetchData = async (url) => {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_PATH}${url}`);
    const data = await res.json();
    return data;
  } catch (error) {
    return [];
  }
};

const Home = () => {
  const [sliderImages, setSliderImages] = useState([]);
  const [videoLink, setVideoLink] = useState("");
  const [publishReview, setPublishReview] = useState([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const [sliderData, videoLinkData, publishReviewData] =
          await Promise.all([
            fetchData("admin/getSliderImages"),
            fetchData(GET_YOUTUBE_LINK),
            fetchData(GET_PUBLISH_RECORD),
          ]);

        setSliderImages(sliderData.slider || []);
        setVideoLink(videoLinkData.youtubeLink?.image || []);
        setPublishReview(publishReviewData.reviews || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataAsync();
  }, []);

  return (
    <>
      {sliderImages && sliderImages.length > 0 && (
        <NewHomeSlider SliderImage={sliderImages} />
      )}
      {videoLink && <AboutUs videoLink={videoLink} />}
      <Cta />
      <ChooseUs />
      <Transformations />
      {publishReview && publishReview.length > 0 && (
        <CustomersSays publishReview={publishReview} />
      )}
    </>
  );
};

export default Home;

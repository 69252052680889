import { our_packages_route } from "@/constants/route_constants";
import { useTranslation } from "react-i18next";
import Link from "next/link";

const Cta = () => {
  const { t } = useTranslation();

  return (
    <section className="call_action_area">
      <div className="container">
        <div className="call_content">
          <h2>{t("Want To Be a Member?")}</h2>
          <p>{t("Want To Be a Member? des")}</p>{" "}
          <Link className="call_btn" href={our_packages_route}>
            {t("Check membership packages")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Cta;

import { useTranslation } from "react-i18next";
import { YouTubeEmbed } from "@next/third-parties/google";

const AboutUs = ({videoLink}) => {
  const { t } = useTranslation();

  return (
    <section className="about_us_area about_us_area_3">
      <div className="container">
        <div className="row aboutus_row">
          <div className="about_content col-lg-6 col-md-12">
            <h1>{t("Who is Raad?")}</h1>
            <p className="bold">
              {t("Fitness expert - on a lifetime journey")}
            </p>
            <p>{t("Home-discription")}</p>
            <p>
              {t(
                "Raad’s journey is a day-to-day documentation of all his achievements and results - shared with the world."
              )}
            </p>
            <a href="#story" className="get_btn">
              {t("Read the story")}
            </a>
          </div>
          <div className="col-lg-6 col-md-12 about_video">
            {videoLink && videoLink.length > 0 && (
              <YouTubeEmbed
                videoid={videoLink?.split("/")[videoLink.split("/").length - 1]}
                loading="lazy"
                width="100%"
                height="100%"
                title={t("Who is Raad?")}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;

import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import Image from "next/image";
import {
  EffectCoverflow,
  Pagination,
  Autoplay,
  Navigation,
} from "swiper/modules";

export default function App({ data }) {
  const [currentCaption, setCurrentCaption] = useState("");

  return (
    <>
      <Swiper
        effect={"coverflow"}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        dir="ltr"
        grabCursor={true}
        loop={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
        style={{
          paddingTop: "80px",
          paddingBottom: "80px",
        }}
navigation={{
          nextEl: ".image-swiper-button-next",
          prevEl: ".image-swiper-button-prev",
          disabledClass: "swiper-button-disabled"
        }}        className="mySwiper ltr">
        {data.map((slide, index) => (
          <SwiperSlide key={index}>
            {({ isActive }) => {
              useEffect(() => {
                if (isActive) {
                  setCurrentCaption(
                    localStorage.getItem("selected_language") === "en"
                      ? slide.caption_en || slide.caption_ar
                      : slide.caption_ar || slide.caption_en
                  );
                }
              }, [isActive]);
              return (
                <div
                  style={{
                    scale: isActive ? 1.4 : 1,
                    transition: "all 0.5s",
                  }}
                  className="transformations-slide">
                  <Image
                    src={slide.image}
                    width={900}
                    height={900}
                    style={{
                      filter: isActive
                        ? "blur(0) grayscale(0) "
                        : "blur(2px) grayscale(0.5)",
                      opacity: isActive ? 1 : 0.5,
                      marginBottom: "10px",
                      objectPosition: "center",
                      transition: "all 0.5s",
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              );
            }}
          </SwiperSlide>
        ))}
      </Swiper>
      <h5 className="mt-4 text-center" style={{ transition: "all 0.5s" }}>
        {currentCaption}
      </h5>
    </>
  );
}

"use client";
import { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { useSwipeable } from "react-swipeable";

const NewHomeSlider = ({ SliderImage }) => {
  let lang = localStorage.getItem("selected_language");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animateIn, setAnimateIn] = useState(true);
  const [isHolding, setIsHolding] = useState(false);

  useEffect(() => {
    if (SliderImage.length > 0) {
      let timer;
      if (isHolding) return;
      if ("requestIdleCallback" in window) {
        const idleCallbackId = window.requestIdleCallback(() => {
          timer = setTimeout(() => rightArrowHandelr(), 5000);
        });

        return () => {
          clearTimeout(timer);
          window.cancelIdleCallback(idleCallbackId);
        };
      } else {
        timer = setTimeout(() => rightArrowHandelr(), 5000);
        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, [currentIndex, SliderImage.length, isHolding]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => rightArrowHandelr(),
    onSwipedRight: () => leftArrowHandelr(),
    onTouchStartOrOnMouseDown: () => setIsHolding(true),
    onTouchEndOrOnMouseUp: () => setIsHolding(false),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const leftArrowHandelr = () => {
    setAnimateIn(true);
    if (currentIndex - 1 < 0) {
      setCurrentIndex(SliderImage.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const rightArrowHandelr = () => {
    setAnimateIn(true);
    if (currentIndex + 1 >= SliderImage.length) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
  };

  let sliderTitle,
    sliderSubTitle,
    sliderDescription,
    sliderButtonContent = "";

  if (SliderImage && SliderImage.length > 0) {
    sliderTitle =
      lang === "en" && SliderImage[currentIndex].title
        ? SliderImage[currentIndex].title
        : SliderImage[currentIndex].title_in_arabic;
    sliderSubTitle =
      lang === "en" && SliderImage[currentIndex].sub_title
        ? SliderImage[currentIndex].sub_title
        : SliderImage[currentIndex].sub_title_in_arabic;
    sliderDescription =
      lang === "en" && SliderImage[currentIndex].description
        ? SliderImage[currentIndex].description
        : SliderImage[currentIndex].description_in_arabic;
    sliderButtonContent = lang === "en" ? "Join Now" : "إشترك الآن";
  }

  return (
    // Container
    <div {...swipeHandlers} className="slider-container">
      {SliderImage && SliderImage.length > 0 && (
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}>
            <Image
              alt={sliderTitle}
              priority
              fill
              src={SliderImage[currentIndex].file}
              style={{
                objectFit: "cover",
                objectPosition: "50% 0%",
                animation: animateIn ? "fadeIn 1s ease-in-out" : "none",
                transition: "opacity 0.5s ease-in-out",
              }}
              quality={100}
              onAnimationEnd={() => setAnimateIn(false)}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            />
          </div>

          {/* Arrows container */}
          <div className="slider-arrows-container">
            {/* Left Arrow */}
            <div className="slider-left-arrow" onClick={leftArrowHandelr}>
              <Image
                fill
                src="/assets/images/slider_left_arrow.png"
                alt="left arrow"
              />
            </div>
            {/* Right Arrow */}
            <div className="slider-right-arrow" onClick={rightArrowHandelr}>
              <Image
                fill
                src="/assets/images/slider_left_arrow.png"
                alt="right arrow"
              />
            </div>
          </div>
        </>
      )}

      <div
        className="slider-content"
        style={lang !== "en" ? { marginLeft: "0", marginRight: "5%" } : {}}>
        {/* Content Animation */}
        <div
          className="slider-content-top"
          style={{
            animation: animateIn ? "slideInUp 0.5s ease-in-out" : "none",
            marginBottom: "40px",
          }}
          onAnimationEnd={() => setAnimateIn(false)}>
          <p className="slider-title">{sliderTitle}</p>
          <p className="slider-sub-title">{sliderSubTitle}</p>
        </div>

        <div
          className="slider-content-middle"
          style={{
            animation: animateIn ? "slideInUp 0.5s ease-in-out" : "none",
            marginBottom: "30px",
          }}
          onAnimationEnd={() => setAnimateIn(false)}>
          <p className="slider-description">{sliderDescription}</p>
        </div>

        <div
          className="slider-content-bottom"
          style={{
            animation: animateIn ? "slideInUp 0.5s ease-in-out" : "none",
          }}
          onAnimationEnd={() => setAnimateIn(false)}>
          <Link href="/our-packages">{sliderButtonContent}</Link>
        </div>
      </div>
    </div>
  );
};

export default NewHomeSlider;
